import React from "react"
import { Router } from "@reach/router"
import UserProvider from "../context/UserProvider"
import StaffRoute from "../components/Route/StaffRoute"
import MessagesProvider from "../components/Users/Messages/context/MessagesProvider"
import TasksProvider from "../context/TasksProvider"
// import BusinessUnitMainView from "../components/Manager/BusinessUnits/BusinessUnitMainView"
import ItemListView from "../components/Manager/Items/ItemListView"
import ItemDetailsView from "../components/Manager/Items/ItemDetailsView"
import ItemCreateView from "../components/Manager/Items/ItemCreateView"
import ItemProductionView from "../components/Manager/Items/ItemProductionView"

import InquiryDetailsView from "../components/Manager/Inquiries/InquiryDetailsView"
import InquiryListView from "../components/Manager/Inquiries/InquiryListView"
import PeopleListView from "../components/Manager/People/PeopleListView"
import PeopleDetailsView from "../components/Manager/People/PeopleDetailsView"
import PeopleCreateView from "../components/Manager/People/PeopleCreateView"
import Accounting from "../components/Manager/Accounting"
import PeopleManagerView from "../components/Manager/People/PeopleManagerView"
import OrgListView from "../components/Manager/Organizations/OrgListView"
import OrgCreateView from "../components/Manager/Organizations/OrgCreateView"
import OrgDetailsView from "../components/Manager/Organizations/OrgDetailsView"
import UserListView from "../components/Manager/Users/UserListView"
import OrderListView from "../components/Manager/Orders/OrderListView"
import OrderDetailsView from "../components/Manager/Orders/OrderDetailsView"
import OrderCreateView from "../components/Manager/Orders/OrderCreateView"
import SupplierBatchDetailsView from "../components/Manager/Supply/SupplierBatchDetailsView"
import CartDetailsView from "../components/Manager/Carts/CartDetailsView"
import CartListView from "../components/Manager/Carts/CartListView"
// import PaymentsListView from "../components/Manager/Accounting/Payments/PaymentsListView"
// import PaymentDetailsView from "../components/Manager/Accounting/Payments/PaymentDetailsView"
import SortConducer from "../context/SortConducer"
import Upload from "../components/Upload"
// import Orders from "../components/Manager/Orders/Orders"
import Vendors from "../components/Manager/Vendors"
import Projects from "../components/Manager/Projects"
import PlanListView from "../components/Manager/Plans/PlanListView"
import PlanDetailsView from "../components/Manager/Plans/PlanDetailsView"

import Files from "../components/Users/Files"
import ProjectDetailsView from "../components/Manager/Projects/ProjectDetails/"
import ProjectCreateView from "../components/Manager/Projects/ProjectCreateView"
import SupplierOrderListView from "../components/Manager/Supply/SupplierOrderListView"
import SupplierOrderDetailsView from "../components/Manager/Supply/SupplierOrderDetailsView"
import SupplierListView from "../components/Manager/Supply/SupplierListView"
import SupplierDetailsView from "../components/Manager/Supply/SupplierDetailsView"
// import ManagerHome from "../components/Manager/ManagerHome"
import StaffHome from "../components/Staff/StaffHome"
import ProposalListView from "../components/Manager/Proposals/ProposalListView"
import ProposalDetailsView from "../components/Manager/Proposals/ProposalDetailsView"
import ProposalCreateView from "../components/Manager/Proposals/ProposalCreateView"
import ServiceHomeView from "../components/Manager/Service/ServiceHomeView"
// import TasksManagerView from "../components/Manager/ManagerHome/TasksManagerView"

import Profile from "../components/Users/Profile"
import Messages from "../components/Users/Messages"
import DocsListView from "../components/Users/Docs/DocsListView"
import DocsDetailsView from "../components/Users/Docs/DocsDetailsView"
import DocsPublishView from "../components/Users/Docs/DocsPublishView"
import "react-perfect-scrollbar/dist/css/styles.css"
import DashboardLayout from "../components/DashboardLayout"
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import useSettings from "../hooks/useSettings"
import MomentUtils from "@date-io/moment"
import { createTheme } from "../theme"
import { create } from "jss"
import { SnackbarProvider } from "notistack"

const jss = create({ plugins: [...jssPreset().plugins] })
const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
        width: "100%",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
    },
  })
)

const Staff = () => {
  useStyles()
  const settings = useSettings()

  return (
    <UserProvider>
      <ThemeProvider theme={createTheme(settings)}>
        <TasksProvider>
          <MessagesProvider>
            <SortConducer>
              <StylesProvider jss={jss}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <SnackbarProvider maxSnack={1}>
                    <DashboardLayout>
                      <Router>
                        <StaffRoute path="/staff" component={StaffHome} />

                        {/* Todo: Usertype-specific messages views. This one is manager view */}
                        <StaffRoute
                          path="/staff/messages"
                          component={Messages}
                        />
                        <StaffRoute
                          path="/staff/docs"
                          component={DocsListView}
                        />
                        <StaffRoute
                          path="/staff/docs/:id"
                          component={DocsDetailsView}
                        />
                        <StaffRoute
                          path="/staff/docs/new"
                          component={DocsPublishView}
                        />
                        {/* <StaffRoute
                          path="/manager/upload"
                          component={Upload}
                        /> */}

                        <StaffRoute
                          path="/staff/people"
                          component={PeopleListView}
                        />
                        <StaffRoute
                          path="/staff/people/create"
                          component={PeopleCreateView}
                        />

                        <StaffRoute
                          path="/staff/people/:uid"
                          component={PeopleDetailsView}
                        />
                        <StaffRoute
                          path="/staff/people/manager"
                          component={PeopleManagerView}
                        />
                        <StaffRoute
                          path="/staff/orgs"
                          component={OrgListView}
                        />
                        <StaffRoute
                          path="/staff/orgs/:ouid"
                          component={OrgDetailsView}
                        />
                        <StaffRoute
                          path="/staff/orgs/new"
                          component={OrgCreateView}
                        />
                        <StaffRoute
                          path="/staff/user"
                          component={UserListView}
                        />
                        <StaffRoute
                          path="/staff/items"
                          component={ItemListView}
                        />
                        <StaffRoute
                          path="/staff/items/:id"
                          component={ItemDetailsView}
                        />
                        <StaffRoute
                          path="/staff/items/create"
                          component={ItemCreateView}
                        />
                        <StaffRoute
                          path="/staff/items/production"
                          component={ItemProductionView}
                        />
                        <StaffRoute
                          path="/staff/carts"
                          component={CartListView}
                        />
                        <StaffRoute
                          path="/staff/carts/:id"
                          component={CartDetailsView}
                        />
                        <StaffRoute
                          path="/staff/orders"
                          component={OrderListView}
                        />
                        <StaffRoute
                          path="/staff/orders/:id"
                          component={OrderDetailsView}
                        />
                        <StaffRoute
                          path="/staff/orders/new"
                          component={OrderCreateView}
                        />
                        <StaffRoute
                          path="/staff/accounting"
                          component={Accounting}
                        />
                        {/* <StaffRoute
                        path="/staff/accounting/payments"
                        component={PaymentsListView}
                      />
                      <StaffRoute
                        path="/staff/accounting/payments/:id"
                        component={PaymentDetailsView}
                      /> */}
                        <StaffRoute
                          path="/staff/inquiries"
                          component={InquiryListView}
                        />
                        <StaffRoute
                          path="/staff/inquiries/:id"
                          component={InquiryDetailsView}
                        />
                        <StaffRoute
                          path="/staff/projects"
                          component={Projects}
                        />
                        <StaffRoute
                          path="/staff/projects/:id"
                          component={ProjectDetailsView}
                        />
                        <StaffRoute
                          path="/staff/projects/create"
                          component={ProjectCreateView}
                        />
                        <StaffRoute
                          path="/staff/plans"
                          component={PlanListView}
                        />
                        <StaffRoute
                          path="/staff/plans/:id"
                          component={PlanDetailsView}
                        />
                        <StaffRoute path="/staff/vendors" component={Vendors} />
                        <StaffRoute
                          path="/staff/supply/suppliers"
                          component={SupplierListView}
                        />
                        <StaffRoute
                          path="/staff/supply/suppliers/:ouid"
                          component={SupplierDetailsView}
                        />
                        <StaffRoute
                          path="/staff/supply/orders/"
                          component={SupplierOrderListView}
                        />
                        <StaffRoute
                          path="/staff/supply/orders/:id"
                          component={SupplierOrderDetailsView}
                        />
                        <StaffRoute
                          path="/staff/supply/batches/:id"
                          component={SupplierBatchDetailsView}
                        />
                        <StaffRoute
                          path="/staff/conversations"
                          component={Messages}
                        />
                        <StaffRoute
                          path="/staff/conversations/:id"
                          component={Messages}
                        />
                        {/* <StaffRoute path="/staff/tasks" component={Tasks} /> */}
                        <StaffRoute path="/staff/files" component={Files} />
                        <StaffRoute path="/staff/profile" component={Profile} />
                        <StaffRoute
                          path="/staff/proposals"
                          component={ProposalListView}
                        />
                        <StaffRoute
                          path="/staff/proposals/new"
                          component={ProposalCreateView}
                        />
                        <StaffRoute
                          path="/staff/proposals/:id"
                          component={ProposalDetailsView}
                        />
                        <StaffRoute
                          path="/staff/service"
                          component={ServiceHomeView}
                        />
                        {/* <StaffRoute
                          path="/staff/tasks"
                          component={TasksStaffView}
                        /> */}
                        {/* <StaffRoute
                          path="/staff/sbu"
                          component={BusinessUnitMainView}
                        /> */}
                      </Router>
                    </DashboardLayout>
                  </SnackbarProvider>
                </MuiPickersUtilsProvider>
              </StylesProvider>
            </SortConducer>
          </MessagesProvider>
        </TasksProvider>
      </ThemeProvider>
    </UserProvider>
  )
}

export default Staff
