import React, { useState } from "react"
import { Box, Container, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import Page from "../../Global/Page"
import Header from "./Header"
const StaffHome = ({ classes }) => {
  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Header />
        <Typography>Staff Home Page</Typography>
      </Container>
    </Page>
  )
}

const styles = theme => ({
  root: {},
})
export default withStyles(styles)(StaffHome)
